import axios from '@axios'
import Cookies from 'js-cookie'

export default {
  async get() {
    const xsrf = Cookies.get('XSRF-TOKEN')

    if (!xsrf) {
      if (window.location.origin !== 'http://localhost:8080') {
        await axios.get(process.env.VUE_APP_CSRF_ENDPOINT)
      }
    }
  },
}
